<template>
  <div class="mx-a mb-16">
    <div class="current-bar pyx mb-16">
      <router-link
        v-for="nav in routerList"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${G_NAV_ID.startsWith(nav.id) ? 'selected' : ''}`"
      >
        {{ nav.title }}
      </router-link>
    </div>
    <!-- 승인 상태 일때 -->
    <div
      class="content-section mb-16"
      style="margin-top: 10px"
      v-if="!isService"
    >
      <div class="content-section-content" style="padding: 24px">
        <div style="color: #828699; font-weight: 500; font-size: 14px">
          신청항목이 없습니다.
        </div>
      </div>
    </div>
    <!-- 거절 일때 -->
    <div
      class="content-section mb-16"
      style="margin-top: 10px"
      v-if="isService && acceptStatus === 'REJ'"
    >
      <div class="content-section-content" style="padding: 24px">
        <div class="flex-align-center">
          <span class="content-approve-title">지난 승인 현황</span>
          <span :class="`content-status-${getAcceptStatusColor()}`">{{
            getAcceptStatus()
          }}</span>
          <span class="content-approve-date-gray">{{ acceptDate }}</span>
        </div>
        <div class="flex-align-center" style="margin-top: 36px">
          <span class="content-approve-title">거절 사유</span>
          <span class="content-approve-reject-reason">{{ rejectReason }}</span>
        </div>
      </div>
    </div>
    <!-- 카테고리 -->
    <div
      class="content-section mb-16"
      style="margin-top: 10px"
      v-if="isService"
    >
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">카테고리</span>
          <span class="fs-16" style="color: #f1431d">*</span>
        </div>
      </div>
      <div class="content-section-content">
        <div>
          <span class="fs-14"
            >선택한 카테고리 : {{ getHighCateName }}
            <span v-if="getHighCateName !== ''">></span>
            <span style="color: #2ccdc3">{{ getCateName }}</span></span
          >
        </div>
        <div class="mt-16 d-flex">
          <div class="content-category-select">
            <div
              :class="`content-category-select-item${
                sendParams.highCategoryEsntlId === highCate.esntlId
                  ? '-active'
                  : ''
              }`"
              v-for="(highCate, index) in highCateList"
              :key="'highCate' + index"
            >
              {{ highCate.title }}
            </div>
          </div>
          <img
            class="content-category-arrow"
            src="@/assets/image/arrow_right_green.svg"
            alt="arrow"
          />
          <div class="content-category-select">
            <div
              :class="`content-category-select-item${
                sendParams.lowCategoryEsntlId === cate.esntlId ? '-active' : ''
              }`"
              v-for="(cate, index) in cateList"
              :key="'cate' + index"
            >
              {{ cate.title }}
            </div>
          </div>
        </div>
        <p class="fs-12 mt-16" style="color: #2ccdc3">
          코스내용과 맞지 않는 카테고리에 등록할 경우 본사에서 연락이 갈 수
          있습니다.
        </p>
        <p class="fs-12 mb-16" style="color: #2ccdc3">
          신중하게 선택 후 작성해 주세요
        </p>
      </div>
    </div>
    <!-- 코스제목 -->
    <div
      class="content-section mb-16"
      style="margin-top: 10px"
      v-if="isService"
    >
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">결제 항목</span>
          <span class="fs-16" style="color: #f1431d">*</span>
        </div>
      </div>
      <div class="content-section-content">
        <div class="course-wrap">
          <draggable v-model="grpArr" handle=".grp-bars">
            <transition-group>
              <payment-grp
                v-for="(v, i) in grpArr"
                :key="v.id"
                :item="v.payment"
                :name="`${v.name} ${i + 1}`"
                :allDisabled="true"
                @remove="onRemoveGrp(v.id)"
                @input="onInputPayment($event, i)"
                group="group"
              />
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="goCompanyRegList"
          >돌아가기</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          style="color: #f1431d"
          @click="openRejectReason"
          v-if="!isRetry && isService"
          >승인 거절</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          @click="doApprove"
          v-if="!isRetry && isService"
          >승인</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          @click="doApprove"
          v-if="isRetry && isService"
          >재승인</button-common
        >
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import PaymentGrp from "@/components/ui/payment/PaymentGrp";

export default {
  name: "PaymentAdd",
  mixins: [],
  components: {
    draggable,
    PaymentGrp,
  },

  /*** data ***/
  data() {
    return {
      titleType: "NEW",
      highCateList: [],
      cateList: [],
      grpArr: [],
      sendParams: {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      },
      isService: false,
      isReset: false,
      isReject: false,
      isRetry: false,
      acceptStatus: null,
      acceptDate: "",
      rejectReason: "",
      toRoute: {
        path: "",
        meta: "",
      },
      esntlId: "",
      routerList: [],
    };
  },

  /*** created ***/
  async created() {
    await this.$store.dispatch("A_NAV_SHOW", false);
  },

  /*** mounted ***/
  async mounted() {
    this.routerList.push({
      id: "11-2-1-3",
      path: `/company/payment/approve/${this.$route.params.id}`,
      title: "신청 항목",
    });
    this.routerList.push({
      id: "11-2-1-4",
      path: `/company/payment/status/${this.$route.params.id}`,
      title: "등록 현황",
    });
    this.routerList.push({
      id: "11-2-1-5",
      path: `/company/payment/prev/${this.$route.params.id}`,
      title: "지난 등록 내역",
    });
    if (this.$route.query.isReject) {
      this.isReject = true;
      await this.getRejectData();
    } else {
      this.isReject = false;
      await this.getApproveData();
    }
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_NAV_ID", "G_REJECT_REASON"]),
    infoMsg() {
      return this.msg !== "" ? this.msg : this.MSG_FORM_EMPTY;
    },
    getHighCateName() {
      let highCateList = this.highCateList.filter(
        (item) => this.sendParams.highCategoryEsntlId === item.esntlId
      );
      return highCateList.length > 0 ? highCateList[0].title : "";
    },
    getCateName() {
      let cateList = this.cateList.filter(
        (item) => this.sendParams.lowCategoryEsntlId === item.esntlId
      );
      return cateList.length > 0 ? cateList[0].title : "";
    },
  },

  /*** watch ***/
  watch: {
    async G_REJECT_REASON(val) {
      // 열림
      if (val) {
        this.rejectReason = this["G_REJECT_REASON"];
        if (this.rejectReason) {
          this.doReject();
        }
      }
    },
    $route(to, from) {
      this.resetParams();
      if (to.fullPath.includes("?")) {
        this.isReject = true;
        this.getRejectData();
      } else {
        this.isReject = false;
        this.getApproveData();
      }
    },
  },

  /*** methods ***/
  methods: {
    /** @ 신청 항목 조회 ***/
    async getApproveData() {
      const rs = await this.$axios({
        url: "/admin/service/app/data",
        params: { partnerEsntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        if (rs.resultData.accept) {
          this.acceptStatus = rs.resultData.accept.acceptStatus;
          this.acceptDate = rs.resultData.accept.acceptDate;
        }
        this.setData(rs.resultData.service);
        if (this.acceptStatus === "REJ" && !rs.resultData.service) {
          this.isRetry = true;
          await this.getRejectData();
        }
        if (rs.resultData.service) {
          await this.getHighCategoryList();
        }
        console.log("신청 항목 조회 완료");
      } else {
        console.log("신청 항목 조회 실패");
      }
    },

    /** @ 거절 항목 조회 ***/
    async getRejectData() {
      const rs = await this.$axios({
        url: "/admin/service/prev/data",
        params: { partnerEsntlId: this.$route.params.id },
      });
      if (rs.result === "SUC") {
        this.rejectReason = rs.resultData.rejectReason;
        this.setData(rs.resultData);
        if (rs.resultData) {
          await this.getHighCategoryList();
        }
        console.log("거절 항목 조회 완료");
      } else {
        console.log("거절 항목 조회 실패");
      }
    },

    /** @ 상세조회 데이터 세팅 ***/
    setData(data) {
      if (data) {
        this.isService = true;
        this.esntlId = data.esntlId;
        this.sendParams.highCategoryEsntlId = data.highCategoryEsntlId;
        this.sendParams.lowCategoryEsntlId = data.lowCategoryEsntlId;
        // 서비스 리스트 순서 정렬
        this.grpArr = [];
        data.serviceList.forEach((item) => {
          const content = JSON.parse(JSON.stringify(item));
          content.itemList.sort((a, b) => {
            return a.numberOrder - b.numberOrder;
          });
          this.grpArr.push({
            id: this.$nnid(),
            name: `항목`,
            payment: content,
          });
        });
        this.grpArr.sort((a, b) => {
          return a.payment.numberOrder - b.payment.numberOrder;
        });
      } else {
        this.isService = false;
      }
    },

    /** @ 카테고리 상위키 조회 ***/
    async getHighCategoryList() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCateList = rs.resultList;
        if (this.sendParams.highCategoryEsntlId) {
          this.selectHighCate(this.sendParams.highCategoryEsntlId);
        }
        console.log("상위 카테고리 조회 완료");
      } else {
        console.log("상위 카테고리 조회 실패");
      }
    },

    /** @ 카테고리 하위키 조회 ***/
    async selectHighCate(id) {
      this.sendParams.highCategoryEsntlId = id;
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: id },
      });
      if (rs.result === "SUC") {
        this.cateList = rs.resultList;
        console.log("하위 카테고리 조회 완료");
      } else {
        console.log("하위 카테고리 조회 실패");
      }
    },

    selectCate(cate) {
      this.sendParams.lowCategoryEsntlId = cate.esntlId;
    },

    resetParams() {
      this.titleType = "NEW";
      this.grpArr = [];
      this.sendParams = {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      };
      this.acceptStatus = null;
      this.acceptDate = "";
      this.rejectReason = "";
    },

    // 결제 그룹 추가
    addPaymentGroup() {
      const content = {
        name: "", // 이름
        type: "", // 유형
        price: "", // 할인전 금액
        minCnt: "", // 월 최소 횟수
        maxCnt: "", // 월 최대 횟수
        itemList: [], // 할인율 배열
        penalty: "", // 해지위약금
      };
      this.grpArr.push({ id: this.$nnid(), name: `항목`, payment: content });
    },

    /** @ 취소 ***/
    onPageReset() {
      this.$openConfirm(
        {
          cbPrmy: this.goPage,
          bodyTx: "작성 중인 내용을 취소하시겠습니까?",
        },
        this
      );
    },

    /**@ 모달 CB ***/
    // 모달콜백: 취소
    goPage() {
      this.isReset = true;
      this.$router.push({
        path: this.toRoute.path,
        meta: { id: this.toRoute.meta },
      });
    },

    // 그룹 삭제
    async onRemoveGrp(id) {
      await this.$openConfirm(
        {
          bodyTx: "해당 그룹을 삭제하시겠습니까?",
          cbPrmy: this.cbDeleteGrp,
          data: { id },
        },
        this
      );
    },

    // 모달콜백: 그룹 삭제
    cbDeleteGrp(val) {
      console.log(val);
      this.grpArr = this.grpArr.filter((item) => item.id !== val.id);
    },

    // 코스 데이터 입력시
    onInputPayment(payment, idx) {
      this.grpArr[idx].payment = payment;
    },

    getAcceptStatus() {
      switch (this.acceptStatus) {
        case "APP":
          return "승인완료";
        case "REJ":
          return "승인거절";
        default:
          return "처리중";
      }
    },

    getAcceptStatusColor() {
      switch (this.acceptStatus) {
        case "APP":
          return "complete";
        case "REJ":
          return "reject";
        default:
          return "process";
      }
    },

    goCompanyRegList() {
      this.$router.back();
    },

    async openRejectReason() {
      this.$store.dispatch("A_REJECT_REASON_DISABLED", false);
      this.$store.dispatch("A_REJECT_REASON", "");
      await this.$openRejectReason({}, this);
    },

    /** @ 승인 ***/
    async doApprove() {
      const rs = await this.$axios({
        url: "/admin/service/approval",
        params: {
          esntlId: this.esntlId,
          partnerEsntlId: this.$route.params.id,
        },
      });
      if (rs.result === "SUC") {
        this.goCompanyRegList();
      } else {
        console.log("승인 실패");
      }
    },

    /** @ 승인 거절 ***/
    async doReject() {
      const rs = await this.$axios({
        url: "/admin/service/reject",
        params: {
          esntlId: this.esntlId,
          partnerEsntlId: this.$route.params.id,
          rejectReason: this.rejectReason,
        },
      });
      if (rs.result === "SUC") {
        this.goCompanyRegList();
      } else {
        console.log("승인 거절 실패");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-circle {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #515466;
  margin-right: 5px;
}
.content-info {
  font-size: 12px;
  font-weight: 400;
  color: #515466;
}
.content-section-header {
  height: 53px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8dae5;
}
.content-section-content {
  padding: 8px 32px;
}
.content-category-select {
  padding: 16px 16px 0 16px;
  width: 510px;
  height: 160px;
  border: 1px solid #d8dae5;
  overflow-y: scroll;
}
.content-category-select-item {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #828699;
  font-size: 14px;
}
.content-category-select-item-active {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #2ccdc3;
  font-size: 14px;
  background: #f1f2f8;
}
.content-category-arrow {
  margin-left: 40px;
  margin-right: 40px;
}
.course-wrap {
  padding: 16px 0;
}
.coach-table-null {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  border: 1px solid #d8dae5;
}
.search-table-wraaper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 320px;
  overflow-y: scroll;
}
.search-table-wraaper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.search-table-wraaper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.content-approve-title {
  color: #828699;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
}
.content-status-complete {
  color: #2ccdc3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.content-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.content-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.content-approve-date {
  color: #007eda;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.content-approve-date-gray {
  color: #9a9eb2;
  font-size: 14px;
  font-weight: 500;
}
.content-approve-reject-reason {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
}
</style>
